<template>
    <div style="width:100%; height:100%; background:#f2f2f1;">
        <div id="login_logo" 
             style="width:100%; height:20%; text-align:center;">
             <div>
                <img src="../../assets/logos/LogiForm_logo.png" style="width:20%; height:20%; margin-top:5%;" />
             </div>
             <div style="width:100%; height:10%; text-align:center; margin-top:1%; color:#345491; font-size:24px; font-weight:bold;">
                <div style="font-size:16px;">
                    Logiform will promptly make you carry out your ideas and show you its effects at once
                </div>
             </div>
        </div>
        <div id="login_form" ref="loginForm" style="width:100%; height:40%; text-align:center;">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                style="padding:2%; backgroundColor:white; border-radius: 5%; margin-top: 5%; margin-left: 30%; margin-right: 30%;"
            >
                <v-text-field
                    ref="cpcode"
                    v-model="cp_code"
                    label="회사코드"
                    clearable
                    required
                    style="width:100%; color:#264b8c;"
                    @keyup.enter="$refs.loginid.focus"
                    @keyup.down="$refs.loginid.focus"
                ></v-text-field>

                <v-text-field
                    ref="loginid"
                    v-model="login_id"
                    label="ID"
                    clearable
                    required
                    style="width:100%; color:#264b8c;"
                    @keyup.prevent.enter="move_pw"
                    @keyup.up="$refs.cpcode.focus"
                ></v-text-field>

                <v-text-field 
                    id="login_pw"  
                    v-model="login_pw"
                    label="PW"
                    clearable
                    required
                    :type="'password'"
                    style="width:100%; color:#264b8c;"
                    @keyup.prevent.enter="enterLogin"
                ></v-text-field>

                <div style="text-align:right;">
                    <v-btn
                        text
                        class="mr-1"
                        style="width:20%; background-color: #df8265; color:white;"
                        @click="dialogRegist = true"
                    >
                        신규등록
                    </v-btn>
                    <v-btn
                        text
                        class="mr-1"
                        style="width:20%; background-color: #4183ff; color:white; margin-left:55%;"
                        @click="checkCpCode"
                    >
                        로그인
                    </v-btn>
                </div>
                <div id="errMsg" style="color:red; text-align:center; padding-top:10%;" v-if="loginError">
                    로그인 정보를 확인해주세요
                </div>
            </v-form>
        </div>
        <div id="login_foot" style="width:100%; height:40%; text-align:center;">
            <div>
                <img src="../../assets/icons/login_back.png" style="width:20%; height:20%; margin-top:5%;" />
            </div>
        </div>

        <v-dialog
            v-model="dialogRegist"
            persistent
            width="30%"
            max-width="1000px"
        >
            <div style="width:100%; height:100%; background:white;">
                <v-container>
                    <v-form>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="registCpName"
                                    label="*회사명"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="registBizNo"
                                    label="*사업자등록번호(-제외)"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="registManager"
                                    label="*담당자명"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="registEmail"
                                    label="*담당자Email"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="registTelNo"
                                    label="연락처"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <div style="width:100%; height:100%; background:white; text-align:center;">
                                    한보일렉트 승인 담당자<br>
                                    070 - 7600 - 8965<br>
                                    * 전화 문의 후 승인이 가능합니다.
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" align="center">
                                <v-btn
                                    tile
                                    color="success"
                                    @click="checkRegistBizNo"
                                >신청</v-btn>
                            </v-col>
                            <v-col cols="6" align="center">
                                <v-btn
                                    tile
                                    color="error"
                                    @click="dialogRegist = false"
                                >취소</v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </div>
            
        </v-dialog>

        <v-dialog
            v-model="dialogAlaram"
            persistent
            width="30%"
            max-width="1000px"
        >
            <div style="width:100%; height:100%; background:white; text-align:center;">
                한보일렉트 승인담당자<br>
                070-7600-8965<br>
                * 전화 문의 후 승인이 가능합니다.
                <v-row>
                    <v-col cols="12" align="center">
                        <v-btn
                            tile
                            color="success"
                            @click="dialogAlaram = false"
                        >확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-dialog>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>

    </div>
</template>

<script>

export default {
    name:'Main',
    data() {
        return {
            valid:true,
            cp_code:null,
            login_id:null,
            login_pw:null,
            uid_company:null,
            loginError:false,

            dialogRegist:false,
            dialogAlaram:false,
            
            registCpName:null,
            registBizNo:null,
            registManager:null,
            registEmail:null,
            registTelNo:null,

            snack:false,
            snackColor:'',
            snackText:'',
        }
    },
    mounted() {
        this.init();
    },
    components: {

    },
    watch: {
        dialogRegist() {
            if(!this.dialogRegist) {
                this.registCpName = null;
                this.registBizNo = null;
                this.registManager = null;
                this.registEmail = null;
                this.registTelNo = null;
            }
        }
    },
    methods: {
        init() {
            const userInfo = this.$store.getters.getLoginDatas;
            const cp_code = userInfo.cp_code
            const login_id = userInfo.user_id

            if(cp_code!=null && cp_code.length>0 && login_id!=null && login_id.length>0) {
                this.cp_code = cp_code
                this.login_id = login_id
            }
        },
        checkCpCode() {
            if(this.cp_code == null || this.cp_code.length<1) {
                this.loginError = true;
                return;
            }

            var obj = {
                cp_code:this.cp_code
            };
            var url = this.$vBACKEND_URL + '/index/login.do?method=checkCpCode';
            var params = '';

            for(var key in obj) {
                var val = obj[key]
                params += '&' + key + '=' + val;
            }

            url += params;

            fetch(url)
            .then(res => res.clone().json())
            .then(res => {
                var result = res.result; 
                this.uid_company = result;
                this.login();
            })
        },

        enterLogin(){
            this.checkCpCode();
        },
        move_pw(){
            document.getElementById('login_pw').focus();
        },
        
        login() {
            if(this.uid_company == null || this.uid_company < 1
                || this.login_id == null || this.login_id.length< 1
                || this.login_pw == null || this.login_pw.length< 1) {
                this.loginError = true;
                return;
            }

            var p_obj = {
                uid_company:this.uid_company,
                user_id:this.login_id,
                password:this.login_pw,
            }

            var url = this.$vBACKEND_URL + '/index/login.do?method=mobileLogin';
            var params = '';

            for(var key in p_obj) {
                var val = p_obj[key]
                params += '&' + key + '=' + val;
            }
            
            url += params;

            fetch(url)
            .then(res => res.json())
            .then(res => {
                var result = res.result;
                if(result!=null && result.length>0) {
                var loginData = result[0];

                this.setLoginUserInfo(loginData, p_obj)
                this.$router.replace('main');
                } else {
                this.loginError = true;
                }
            })
        },

        setLoginUserInfo(loginData, obj) {
            obj.uid_company = loginData.uid_company
            obj.user_id = loginData.user_id
            obj.user_uid = loginData.unique_id
            obj.user_name = loginData.user_name
            obj.cp_code = loginData.cp_code
            obj.cp_name = loginData.cp_name
            obj.codeType = loginData.code_type
            this.$store.commit('setLoginDatas', obj);
        },

        checkRegistBizNo() {
            if(this.registCpName==null || this.registCpName.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '회사명을 입력해주세요.'
                return;
            }
            if(this.registBizNo==null || this.registBizNo.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '사업자등록번호를 입력해주세요.'
                return;
            } else if(this.registBizNo.length != 10) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '사업자등록번호를 확인해주세요.'
                return;
            }
            if(this.registManager==null || this.registManager.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '담당자명을 입력해주세요.'
                return;
            }
            if(this.registEmail==null || this.registEmail.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '담당자Email를 입력해주세요.'
                return;
            }

            // var url = 'http://localhost:8080/admin.do?method=checkWmsBizNo';
            // var url = 'http://103.60.126.141:8080/admin.do?method=checkWmsBizNo';
            var url = 'https://hanbo.msysict.co.kr/admin.do?method=checkWmsBizNo';
            var obj = {
                biz_no:this.registBizNo,
            }

            var params = '';
            for(var key in obj) {
                params += '&' + key + '=' + obj[key]
            }
            url += params;

            fetch(url)
                .then(res => res.clone().json())
                .then(res => {
                    const result = res.result;

                    if(result) {
                        this.sendRegistInfo();
                    } else {
                        this.snack = true
                        this.snackColor = 'red'
                        this.snackText = '이미 등록된 사업자입니다. 확인 후 다시 요청해주시기 바랍니다.'
                    }
                })
                .catch(err => {
                    console.log(err)
                    this.snack = true
                    this.snackColor = 'red'
                    this.snackText = '요청에 실패했습니다. 잠시 후 요청해주세요.'
                })
        },

        sendRegistInfo() {
            if(this.registCpName==null || this.registCpName.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '회사명을 입력해주세요.'
                return;
            }
            if(this.registBizNo==null || this.registBizNo.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '사업자등록번호를 입력해주세요.'
                return;
            } else if(this.registBizNo.length != 10) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '사업자등록번호를 확인해주세요.'
                return;
            }
            if(this.registManager==null || this.registManager.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '담당자명을 입력해주세요.'
                return;
            }
            if(this.registEmail==null || this.registEmail.length<1) {
                this.snack = true
                this.snackColor = 'red'
                this.snackText = '담당자Email를 입력해주세요.'
                return;
            }

            // var url = 'http://localhost:8080/admin.do?method=manageWmsUser';
            // var url = 'http://103.60.126.141:8080/admin.do?method=manageWmsUser';
            var url = 'https://hanbo.msysict.co.kr/admin.do?method=manageWmsUser';
            var obj = {
                type:'ADD',
                cp_name:this.registCpName,
                biz_no:this.registBizNo,
                user_name:this.registManager,
                user_email:this.registEmail,
                tel_no:this.registTelNo
            }

            var params = '';
            for(var key in obj) {
                params += '&' + key + '=' + obj[key]
            }
            url += params;

            fetch(url)
                .then(res => {
                    this.snack = true
                    this.snackColor = 'success'
                    this.snackText = '요청에 성공했습니다. 감사합니다.'
                })
                .catch(err => {
                    console.log(err)
                    this.snack = true
                    this.snackColor = 'red'
                    this.snackText = '요청에 실패했습니다. 잠시 후 요청해주세요.'
                })
                .finally(() => this.dialogRegist = false)

        }
    }
}
</script>

<style scoped>
 
</style>
